import React  from "react"
import styled, { css } from "styled-components"
import { motion } from "framer-motion"
import tw from "tailwind.macro"
import { Link } from "gatsby"
import { UseGlobalStateContext } from "../context/globalContext"
import { getTheme } from "../themes/theme"
import { BodyText } from "../elements/shared/texts"


const MenuItemWrapper = styled(motion.div)`
    ${tw`rounded-lg w-auto py-2 px-4 focus:shadow-outline flex items-center`};
    ${ props =>
        props.isnav && css`
               width: auto;
               border-radius: 15px;
               text-align: center;
               @media (max-width: 768px) {
                  font-size: ${props => props.theme.measurements.xlFontSize};
                  margin: 20px 0; 
              }

              @media (max-width: 640px) {
                margin: 10px 0; 
              }
            `
      }
    
`

const LinkMenu = styled(Link)`
 ${tw`text-center focus:outline-none `};
  color: ${props => props.theme.colors.primaryText};

  ${props => props.isnav && css`
  
      @media (max-width: 768px) {
        font-size: ${props => props.theme.measurements.xlFontSize};
      }
  `}

  &:hover {
    color: ${props => props.theme.colors.primary};
  }

  &.active{
    color: ${props => props.theme.colors.primary};
  }
`

const MenuItem = ({title, path, isNav, isExternal}) => {
  const { currentTheme } = UseGlobalStateContext()
  const theme = getTheme(currentTheme)

  return (
    <MenuItemWrapper isnav={isNav} whileHover={ 
      {
        backgroundColor: theme.colors.lightPrimary,
        y: -5
      } 
    }>
      <BodyText>
        {isExternal ? <a href={`/${path}`}> {title} </a> : <LinkMenu  activeClassName="active" isnav={isNav}  to={`${path}`} > {title} </LinkMenu>} 
      </BodyText>
    </MenuItemWrapper>
  )
}

export  default  MenuItem