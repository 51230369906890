import React  from "react"
import { createGlobalStyle, ThemeProvider } from "styled-components"
import { getTheme } from "../themes/theme"
import {
  UseGlobalDispatchContext,
  UseGlobalStateContext,
} from "../context/globalContext"
import NavigationBar from "./navbar"
import Footer from "./Footer"
import LegalModal from "./Legal/LegalModal"
import { GlobalActionsType } from "../utils/constants"
import { Helmet } from "react-helmet"
import StorageService from "../../src/helpers/StorageService"
import AuthenticationService from "../web_service/AuthenticationService"
import { useSiteMetadata } from "../utils/useSiteMetadata"
import { IsBrowser } from "../helpers/utils"
import { SkeletonTheme } from "react-loading-skeleton"
import LegalFooter from "./Legal/LegalFooter"
import ComingSoonComponent from "./ComingSoonComponent";

const GlobalStyles = createGlobalStyle`

    * {
        box-sizing: border-box;
        margin: 0;
        padding: 0;

    }


    body, html {
        font-family:${props => props.theme.fonts.main};
        -webkit-font-smoothing: antialiased;
        text-rendering: optimizeLegibility;
        font-variant-ligatures: common-ligatures; 
        background-color: ${props => props.theme.colors.background};
        color: ${props => props.theme.colors.primaryText};
        height: 100%;
        transition: background 0.5s, color 0.5s;
        scroll-behavior: smooth;
        font-weight: 300;
        letter-spacing: 0;
        font-size: 20px;
        -moz-osx-font-smoothing: grayscale;
        line-height: 1.5em;

        ::-webkit-scrollbar {
            width: 10px;          
        }
        
        ::-webkit-scrollbar-thumb {   
            border-radius: 5px;
            background:  ${props => props.theme.colors.border};
        }
        
        ::-webkit-scrollbar-track {     
            background-color: transparent;
        }
    }
`


const Layout = ({ children, hideFooter }) => {
  const { currentTheme } = UseGlobalStateContext()
  const dispatch = UseGlobalDispatchContext()
  const savedUser = StorageService.getService().getUser()
  const metadata = useSiteMetadata()

  React.useEffect(() => {
    dispatch({ type: GlobalActionsType.USER, userData: savedUser })
  }, [])


  if (metadata.inMaintenance === "true") {
    return (
      <ThemeProvider theme={getTheme(currentTheme)}>
        <GlobalStyles />
        <ComingSoonComponent />
      </ThemeProvider>
    )
  }

  return (
    <ThemeProvider theme={getTheme(currentTheme)}>
      <GlobalStyles />
      { !hideFooter && <NavigationBar/> }

      <Helmet>
        <meta charSet="utf-8" />
        <title>Liquidcoder limited</title>
      </Helmet>
      <SkeletonTheme color={currentTheme === "dark" ? "#202020" : "#d0d5d7"}
                     highlightColor={currentTheme === "dark" ? "#444" : "#e6ebed"}>
        {children}
      </SkeletonTheme>

      { !hideFooter && <Footer />}
      { !hideFooter && <LegalFooter />}
      <LegalModal />
    </ThemeProvider>
  );
}

export default Layout
