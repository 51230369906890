import React from "react"
import styled from "styled-components"
import { UseGlobalDispatchContext } from "../../context/globalContext";
import { GlobalActionsType } from "../../utils/constants";
import { NakedGrayButton } from "../../elements/shared/buttons";
import { NakedGrayAnchorTag } from "../../elements/shared/texts";

const Container = styled.div`
    padding: 30px;
       border-top: 0.5px solid ${props => props.theme.colors.border};

     background:  ${props => props.theme.colors.legalBackground};
    a{
    text-decoration: underline;
    }
`

const LegalFooter = () => {

  const dispatch = UseGlobalDispatchContext()

  const showPrivacy = (e) => {
    dispatch({type: GlobalActionsType.PRIVACY, showPrivacy: true})
  }

  const showTerms = () => {
    dispatch({type: GlobalActionsType.TERMS, showTerms: true})
  }

  const showDisclaimer = () => {
    dispatch({type: GlobalActionsType.DISCLAIMER, showDisclaimer: true})
  }

  return (
    <Container id={"legal"}>
        <div className={"container mx-auto md:flex justify-between items-center"}>
          <small> <NakedGrayButton onClick={showPrivacy}>Privacy policy</NakedGrayButton>&nbsp;&nbsp;-&nbsp;<NakedGrayButton onClick={()=>{ showTerms() }}>Terms and conditions</NakedGrayButton></small>
          <div className={"mt-4 md:mt-0"}>
            <small>
              Questions? Email us at <NakedGrayAnchorTag href="mailto:contact@liquidcoder.com">contact@liquidcoder.com</NakedGrayAnchorTag>
            </small>
            <br/>
            <small>
              Liquidcoder Ltd. &#169; 2020. All rights reserved.
            </small>
          </div>
        </div>
    </Container>
  )
}

export default LegalFooter