import React, { useCallback, useState } from "react"
import { useGoogleReCaptcha } from "react-google-recaptcha-v3"


export default function(initialState, validate, authenticate) {
  const [values, setValues]  = useState(initialState)
  const [errors, setErrors] = useState({})
  const [isSubmitting, setSubmitting] = useState(false)
  const { executeRecaptcha } = useGoogleReCaptcha();
  const [token, setToken] = useState('');
  const [dynamicAction, setDynamicAction] = useState('AUTH');


  const clickHandler = useCallback(async () => {
    if (!executeRecaptcha) {
      return;
    }
    const result = await executeRecaptcha('dynamicAction');

    setToken(result);
  }, [dynamicAction]);

  React.useEffect(() => {

    // if (!token) {
    //   setSubmitting(false)
    //   // clickHandler()
    //   return
    // }

    if (isSubmitting) {
      const noErrors = Object.keys(errors).length === 0
      if (noErrors) {
          authenticate()
          setSubmitting(false)
      } else {
          setSubmitting(false)
      }
    }
  },[errors])

  function handleChange(event) {
 
      setValues({
        ...values,
        [event.target.name]: event.target.type === "checkbox" ? event.target.checked : event.target.value.trim()
      })
  }

  function handleBlur() {
    const errors = validate(values)
    setErrors(errors)
  }

  function handleSubmit(event) {
      event.preventDefault()
    const errors = validate(values)
    setErrors(errors)
    setSubmitting(true)
  }

  return {
      handleChange,
      values,
      handleSubmit,
      handleBlur,
      errors,
     isSubmitting
  }
}