import React from "react"
import { BodyText, GrayText, H4, ErrorWrapper, A } from "../elements/shared/texts";
import styled, { css } from "styled-components"
import tw from "tailwind.macro"
import emailValidation from "../helpers/validations/emailValidation.js"
import AuthenticationService from "../web_service/AuthenticationService"
import useValidation from "../helpers/useValidation"
import LoadingDots from "../elements/shared/loaders/LoadingDots"
import { motion } from "framer-motion"
import {  NakedAccentButton } from "../elements/shared/buttons";
import { UseGlobalDispatchContext } from "../context/globalContext";
import { GlobalActionsType } from "../utils/constants";

const Input = styled("input")`
  ${tw`px-2 py-2 w-full`};
  background: ${props => props.theme.colors.background};
  border-color: ${props => props.theme.colors.border};
   font-size: 16px;

  ${props =>
    props.hasErrors &&
    css`
      border-color: ${props => props.theme.colors.cleanRed};
    `}
`

const InlineForm = styled("form")`
  ${tw`flex`};
  border-radius: ${props => props.theme.measurements.smBorderRadius};
  border-width: ${props => props.theme.measurements.borderWidth};
  background: ${props => props.theme.colors.background};
  border-color: ${props => props.theme.colors.border};
  overflow: hidden;

  ${props =>
    props.hasErrors &&
    css`
      border-color: ${props => props.theme.colors.cleanRed};
    `}
`

const SendButton = styled(motion.button)`
  padding: 10px;
  color: ${props => props.theme.colors.textInverse};
  background-color: ${props => props.theme.colors.primary};
`

const INITIAL_STATE = {
  email: "",
}

const Subscribe = () => {
  const [isLoading, setLoading] = React.useState(false)
  const [httpResponse, setHttpResponse] = React.useState(false)
  const dispatch = UseGlobalDispatchContext()

  const authenticateUser = () => {
    if (isLoading) {
      return
    }
    setLoading(true)
    const { email } = values

    const data = {
      email: email.toLowerCase(),
    }

    AuthenticationService.getService().subToNewsletter(
      data,
      res => {
        setHttpResponse({
          success: true,
          hasSubmitted: true,
          message: "Thank you for subscribing!",
        })
        setLoading(false)
      },
      err => {
        if (err.response) {
          setHttpResponse({
            success: false,
            hasSubmitted: true,
            message: err.response.data,
          })
        }
        setLoading(false)
      }
    )
  }

  const {
    handleChange,
    handleBlur,
    errors,
    values,
    handleSubmit,
  } = useValidation(INITIAL_STATE, emailValidation, authenticateUser)

  React.useEffect(() => {
    setHttpResponse({
      success: false,
      hasSubmitted: false,
      message: errors.email,
    })
  }, [errors.email])

  const showPrivacy = () => {
    dispatch({type: GlobalActionsType.PRIVACY, showPrivacy: true})
  }

  const showTerms = () => {
    dispatch({type: GlobalActionsType.TERMS, showTerms: true})
  }


  return (
    <div className="text-center md:text-left w-full sm:w-4/5 md:w-3/5 lg:w-3/6 xl:w-2/5 md:mx-0 mx-auto">
      <H4>Weekly newsletter</H4>
      <GrayText className="sm:text-center md:text-left sm:mx-auto md:mx-0 my-4">
        <BodyText>
          Get weekly notifications about new blog posts and course lessons, and stay in the know about upcoming courses.
        </BodyText>
      </GrayText>

      <InlineForm onSubmit={handleSubmit} hasErrors={errors.email}>
        <Input
          type="text"
          onChange={handleChange}
          onBlur={handleBlur}
          name={"email"}
          value={values.email}
          placeholder="Your email address"
          hasErrors={errors.email}
        />
        <SendButton
          type="submit"
          disabled={isLoading}
        >
          {isLoading ? <LoadingDots isBigger /> : <p> Send </p>}
        </SendButton>
      </InlineForm>
      <GrayText className="text-left">
      {/*  <small>*/}
      {/*    I will start sending email newsletters once in a while, so in order to get notified when I release new content, make sure to follow me on twitter <A*/}
      {/*    href="https://twitter.com/liquidcoder" target={"_blank"}>@liquidcoder</A> .*/}
      {/*  </small>*/}
      {/*<br/><br/>*/}
        <small>
          <b>Note:</b> By clicking on the send button, you agree to our <NakedAccentButton onClick={() => { showPrivacy() }} > Privacy policy </NakedAccentButton> and <NakedAccentButton onClick={() => { showTerms() }}> Terms of service </NakedAccentButton> as well as to receive email notifications about news courses and blog articles.
          articles.
        </small>
      </GrayText>
      {httpResponse.success && <small>{httpResponse.message}</small>}

      {!httpResponse.success && (
        <ErrorWrapper>
          <small>{httpResponse.message}</small>
        </ErrorWrapper>
      )}
    </div>
  )
}

export default Subscribe
