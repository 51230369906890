import React, {useEffect, useState} from "react"
import tw from "tailwind.macro"
import { UseGlobalStateContext } from "../context/globalContext"
import { AccentLink } from "../elements/shared/buttons"
import { BodyText, BoldTextWrapper, P } from "../elements/shared/texts"
import { UserLoggedInState } from "../utils/constants";
import { MotionLinkWrapper } from "../elements/shared/buttons/MotionLinkWrapper"
import { graphql, useStaticQuery } from "gatsby"
import styled, { css } from "styled-components"


const Container = styled.div`
  width: max(300px, min(90%, 400px));

  ${props =>
          !props.isHome &&
          css`
            margin: 0 auto;
      `}
  

`

const SubscriptionComponent = (props) => {
  const { userData } = UseGlobalStateContext()
  const [buttonText, setButtonText] = useState("")
  const [tagLine, setTagLine] = useState("")
  const [ loggedInState, setLoggedInState ] = useState(UserLoggedInState.LOADING)

  const data = useStaticQuery(
    graphql`
        query {
            pricing: contentfulHomePricingJsonNode {
                premium {
                    monthly_price
                    yearly_price
                }
            }
        }
    `
  )

  useEffect(() => {
    if (userData) {
      if (userData.isPremium) {
        setButtonText("Go to dashboard")
        setTagLine("Manage your personal and subscription preferences.")
      } else {
        setButtonText("Support us now")
        setTagLine(`Only $${data.pricing.premium.monthly_price} a month. Cancel any time!`)
      }
      setLoggedInState(UserLoggedInState.LOGGED_IN)
    } else {
      setButtonText("Support us now")
      setTagLine(`Only $${data.pricing.premium.monthly_price} a month. Cancel any time!`)
      setLoggedInState(UserLoggedInState.NOT_LOGGED_IN)
    }
  }, [userData])



  return (
    <Container isHome={props.isHome}>
      { loggedInState !== UserLoggedInState.LOADING
        &&
        <MotionLinkWrapper>
          <AccentLink to={`/account/dashboard`} className=" my-4 mx-auto ">
            <BoldTextWrapper>
              <BodyText>{buttonText}</BodyText>
            </BoldTextWrapper>
          </AccentLink>
        </MotionLinkWrapper>
      } 

      <BodyText>
        {tagLine}
      </BodyText>
    </Container>
  )
}

export default SubscriptionComponent
