import React from "react"
import { graphql, useStaticQuery } from "gatsby";
import LegalComponent from "./LegalComponent"

const Disclaimer = () => {


  const data = useStaticQuery(
    graphql`
        query {
            legal: allContentfulLegal(filter: {name: {eq: "Disclaimer"}}) {
                edges {
                    node {
                        content {
                            content
                        }
                    }
                }
            }
        }
    `
  )

  const html = data.legal.edges[0].node.content.content

  return (
    <LegalComponent title={"Disclaimer"} html={html} />
  )
}
//
// export const query = graphql`
//     {
//         allContentfulLegal(filter: {name: {eq: "Disclaimer"}}) {
//             edges {
//                 node {
//                     content {
//                         content
//                     }
//                     name
//                 }
//             }
//         }
//     }
// `


export default Disclaimer

