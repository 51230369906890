import {keyframes} from "styled-components"


export const floatingComponent = keyframes`
  0% {
    width: 0;
    height: 0;
  }
  100% {
    width: 100%;
    height: 100%;
  }
`

export const loading = keyframes`
  from {
    background-position: -2000px;
  }
  to { 
    background-position: 0;
  }
`

export const menu = keyframes`
  from {
    background-position: 0;
  }
  to { 
    background-position: 100vh;
  }
`

export const blink = keyframes`
    0% {
      opacity: .2;
    }

    20% {
      opacity: 1;
    }
  
    100% {
      opacity: .2;
    }
`

export  const spinner = keyframes`
 0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
`

export const blob = keyframes`
  0%   { transform: scale(1)   }
  38%  { transform: scale(0.8, 1) rotate(10deg); }
  40%  { transform: scale(0.8, 1) rotate(10deg); }
  78%  { transform: scale(0.8)  rotate(-20deg); }
  80%  { transform: scale(0.8)  rotate(-20deg); }
  100% { transform: scale(1)  
  }
`

export const gradientFlow =keyframes`
  0%{ 
    background-position: 0 50%;
  }

  50%{ 
    background-position: 100% 50%;
  }

  100%{ 
    background-position: 0 50%;
  }

`

export const pulse = keyframes`
  from {
        transform : scale(1);
        opacity   : 1;
    }
    50% {
        transform : scale(0.75);
        opacity   : 0.25;
    }
    to {
        transform : scale(1);
        opacity   : 1;
    }
`
