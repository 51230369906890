import  React  from "react";
import styled, { css } from "styled-components"
import tw from "tailwind.macro"
import { BodyText, H4, GrayTextWrapper } from "../elements/shared/texts"
import { motion } from "framer-motion"
import { UseGlobalStateContext } from "../context/globalContext"
import { getTheme } from "../themes/theme"

const SocialIcon = styled("img")`
    ${tw``};
    height: 25px;
    width:auto;
    filter: ${props => props.theme.colors.filter};

    ${props =>
      props.isTwitter &&
      css`
        &:hover {
          filter: invert(48%) sepia(77%) saturate(1851%) hue-rotate(176deg)
            brightness(100%) contrast(91%);
        }
      `}


    ${props =>
      props.isInstagram &&
      css`
        &:hover {
          filter: invert(84%) sepia(45%) saturate(7208%) hue-rotate(318deg)
            brightness(84%) contrast(120%);
        }
      `}


    ${props =>
      props.isFacebook &&
      css`
        &:hover {
          filter: invert(40%) sepia(46%) saturate(767%) hue-rotate(182deg)
            brightness(86%) contrast(88%);
        }
      `}
`

const SocialLink = styled(motion.a)`
  ${tw`flex my-1`};
  padding: 8px 10px;
  border-radius: ${props => props.theme.measurements.smBorderRadius};

  &:hover {
    
    ${props =>
      props.isTwitter &&
      css`
          filter: invert(48%) sepia(77%) saturate(1851%) hue-rotate(176deg)
            brightness(100%) contrast(91%);
      `}


    ${props =>
      props.isInstagram &&
      css`
          filter: invert(84%) sepia(45%) saturate(7208%) hue-rotate(318deg)
            brightness(84%) contrast(120%);
        
      `}


    ${props =>
      props.isFacebook &&
      css`
  
          filter: invert(40%) sepia(46%) saturate(767%) hue-rotate(182deg)
            brightness(86%) contrast(88%);
        
      `}

  }

`

const SocialMedia = ({data}) => {

  const { currentTheme } = UseGlobalStateContext()
  const theme = getTheme(currentTheme)

    return (
        <div className="text-left mx-auto mt-8 sm:mt-0">
        <GrayTextWrapper>
          <H4>
            Find us on:
          </H4>
        </GrayTextWrapper>
        <div className=" mx-auto md:mx-0 mt-2">
          <SocialLink  href="https://www.twitter.com/liquidcoder"
          isTwitter
          whileTap={{ scale: 0.9}}
          whileHover={ 
            {
              backgroundColor: theme.colors.lightPrimary,
              y: -5
            } 
          }

          >
            <SocialIcon
             
              src={data.twitter.publicURL}
              alt="Twitter"
              
            />
            <BodyText className={"pl-2"}>
              Twitter
            </BodyText>
          </SocialLink>

          <SocialLink 
          isInstagram
          whileTap={{ scale: 0.9}}
          whileHover={ 
            {
              backgroundColor: theme.colors.lightPrimary,
              y: -5
            } 
          }
          href="https://www.instagram.com/liquidcoderr"
          >
            <SocialIcon
              
              src={data.instagram.publicURL}
              alt="Instagram"
            />
            <BodyText className={"pl-2"}>
              Instagram
            </BodyText>
          </SocialLink>
          <SocialLink 
          isFacebook
          whileTap={{ scale: 0.9}}
          whileHover={ 
            {
              backgroundColor: theme.colors.lightPrimary,
              y: -5
            } 
          }
          href="https://www.facebook.com/liquidcoder"
          >
            <SocialIcon
              
              src={data.facebook.publicURL}
              alt="Facebook"
            />

            <BodyText className={"pl-2"}>
              Facebook
            </BodyText>
          </SocialLink>
        </div>
        </div>
    )
}

export default SocialMedia