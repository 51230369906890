import React from 'react';
import styled, { css } from 'styled-components'
import tw  from 'tailwind.macro';

const MenuButton = styled.div`
    ${tw`flex justify-center items-center md:hidden z-50`};
    width: 25px;
    height: 30px;
    cursor: pointer;
`

const MenuButtonBurger = styled.div`
    width: 30px;
    height: 2px;
    background-color: ${props => props.theme.colors.primaryText};
    border-radius: 1px;
    transition: all .2s ease-in-out;

    &::before, &::after {
        content: '';
        position: absolute;
        width: 30px;
        height: 2px;
        background-color: ${props => props.theme.colors.primaryText};
        border-radius: 1px;
        transition: all .2s ease-in-out;
    }

    ${props => props.isOpen &&
            css`
            transform: translateX(-50px);
            background: transparent;

            &::before {
                transform: rotate(45deg) translate(35px, -35px)
            }

            &::after {
                transform: rotate(-45deg) translate(35px, 35px)
            }

        `
        
    }

    ${props => !props.isOpen &&
        css`

            &::before {
                transform: translateY(-13px)
            }

            &::after {
                transform: translateY(13px)
            }

        `
    
    }

`


const HambourgerButton = props => {

    return (
        <MenuButton onClick={event => { props.toggleMenu() }} >
            <MenuButtonBurger isOpen={props.isOpen}>

            </MenuButtonBurger>
        </MenuButton>
    )
}

export default HambourgerButton