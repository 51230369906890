import React from "react"
import { PrimaryButton } from "./index"


export const SubmitButton = () => {

  return (
    <PrimaryButton className="mx-auto mt-6"
                   type={"submit"}
                   whileHover={{ scale: 1.1, y: -5 }}
                   whileTap={{ scale: 0.9, y: 0 }}
    > Submit </PrimaryButton>
  )
}