import React from "react"
import styled from "styled-components"
import tw from "tailwind.macro"
import { GrayTextWrapper} from "../elements/shared/texts"
import { ValidatedInput } from "../elements/shared/inputs"
import { NakedPrimaryButton, SubmitButton } from "../elements/shared/buttons"
import useValidation from "../helpers/useValidation"
import AuthenticationService from "../web_service/AuthenticationService"
import emailValidation from "../helpers/validations/emailValidation"
import { ModalType } from "../utils/constants"
import Form from "../elements/shared/form/Form"
import { ResponseStatus } from "../utils/constants.js"

const Container = styled.div`
  ${tw`flex flex-col items-center h-full `}
`

const INITIAL_STATE = {
  email: "",
}

const ForgotPassword = ({ showLogIn, requestType, setProcessing, setResponse }) => {

  const authenticateUser = () => {
    const service =  AuthenticationService.getService()
    setProcessing(true)
    const { email } = values

    const data = {
      Email: email.toLowerCase()
    }

    switch (requestType) {
      case ModalType.FORGOT_PASSWORD:
        service.forgotPassword(data,
          res => { showSuccess(res) },
          err => { showFailure(err) })
        break
      case ModalType.EMAIL_CONFIRMATION:
        service.resend_confirmation(data,
          res => { showSuccess(res) },
          err => { showFailure(err) })
        break
      default:
        console.log("Wrong request type")
    }

  }

  const { handleChange, handleBlur, errors, values, handleSubmit } = useValidation(INITIAL_STATE, emailValidation, authenticateUser)


  const showSuccess = (res) => {
    setResponse({
      status: ResponseStatus.Success,
      message: res.data
    })
  }

  const showFailure = (err) => {
    if (err.response) {
      setResponse({
        status: ResponseStatus.Failure,
        message: err.response.data
      })
    } else {
      setResponse({
        status: ResponseStatus.Failure,
        message: "Oops! Something went wrong... Please check yout internet connection"
      })
    }
  }

  return (
    <Container>
      <Form onSubmit={handleSubmit}>
        <ValidatedInput
          onChange={handleChange}
          onBlur={handleBlur}
          name={"email"}
          value={values.email}
          placeholder={"Email"}
          message={errors.email}
        />
        <SubmitButton />
      </Form>
      <GrayTextWrapper className={"pt-8 text-center"}>
        <span>
          Want to login?&nbsp;
        </span>
        <NakedPrimaryButton onClick={showLogIn}>
          Log in
        </NakedPrimaryButton>
      </GrayTextWrapper>
    </Container>
  )
}

export  default ForgotPassword