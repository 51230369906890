import React from "react"
import styled from "styled-components"
import tw from "tailwind.macro"
import { A, GrayText, GrayTextWrapper } from "../elements/shared/texts";
import { ValidatedInput } from "../elements/shared/inputs"
import { NakedPrimaryButton, PrimaryButton, SubmitButton } from "../elements/shared/buttons"
import useValidation from "../helpers/useValidation"
import loginValidation from "../helpers/validations/loginValidation"
import AuthenticationService from "../web_service/AuthenticationService"
import StorageService from "../helpers/StorageService"
import { UseGlobalDispatchContext } from "../context/globalContext"
import Form from "../elements/shared/form/Form"
import { ResponseStatus } from "../utils/constants.js"
import { GlobalActionsType } from "../utils/constants"
import { IsBrowser } from "../helpers/utils.js"
import { navigate } from "@reach/router"
import { useGoogleReCaptcha } from "react-google-recaptcha-v3"

const Container = styled.div`
  ${tw`flex flex-col items-center h-full`}
`


const INITIAL_STATE = {
  email: "",
  password: ""
}



const LogIn = ({showSignUp, showForgotPassword, showEmailConfirmation, setProcessing, setResponse}) => {

  const  dispatch  = UseGlobalDispatchContext()


  const updateLoggedInStatus = (user) => {
    dispatch({type: GlobalActionsType.IS_LOGGED_IN, isLoggedIn: true})
    dispatch({type: GlobalActionsType.USER, userData: user})
    if (IsBrowser) {
      navigate("/account/dashboard")
    }
  }

  const authenticateUser = () => {
    setProcessing(true)
    const { email, password } = values

    const data = {
      Email: email.toLowerCase(),
      Password: password
    }

    AuthenticationService.getService().login(data, user => {
      updateLoggedInStatus(user)
    }, err => {
      console.log("Error loging in: ", err.response)
      if (err.response) {
        setResponse({
          status: ResponseStatus.Failure,
          message: err.response.data
        })
      } else {
        setResponse({
          status: ResponseStatus.Failure,
          message: "Oops! Something went wrong... Please check yout internet connection"
        })
      }
    })
  }

  const { handleChange, handleBlur, errors,  values, handleSubmit } = useValidation(INITIAL_STATE, loginValidation, authenticateUser)

  return (
    <Container>
      <Form onSubmit={handleSubmit}>
          <ValidatedInput
            onChange={handleChange}
            onBlur={handleBlur}
            name={"email"}
            value={values.email}
            placeholder={"Email"}
            message={errors.email}
          />
        <ValidatedInput
          onChange={handleChange}
          onBlur={handleBlur}
          name={"password"}
          value={values.password}
          placeholder={"Password"}
          message={errors.password}
          isSecure
        />
        {/*<small>*/}
        {/*  I will start sending email newsletters once in a while, so in order to get notified when I release new content, make sure to follow me on twitter <A*/}
        {/*  href="https://twitter.com/liquidcoder" target={"_blank"}>@liquidcoder</A> .*/}
        {/*</small>*/}
        <SubmitButton />

      </Form>
      <GrayTextWrapper className={"py-8 text-center"}>
        <span>
          Don't have an account?&nbsp;
        </span>
        <NakedPrimaryButton onClick={showSignUp}>
          Sign up
        </NakedPrimaryButton>
      </GrayTextWrapper>
      <NakedPrimaryButton onClick={showForgotPassword}>
        Forgot password?
      </NakedPrimaryButton>

      <p className={"py-4"}>
        OR
      </p>

      <NakedPrimaryButton onClick={showEmailConfirmation}>
        Re-send confirmation email
      </NakedPrimaryButton>
    </Container>
  )
}

export  default LogIn