import React from "react"
import styled from "styled-components"
import tw from "tailwind.macro"


const ModelWrapper = styled.div`
  ${tw`fixed top-0 bottom-0 left-0 right-0`};
  z-index: 100;
`

const BackdropWrapper = styled.div`
  ${tw`fixed top-0 bottom-0 left-0 right-0`};
  z-index: 100;
  background-color: rgba(0,0,0, 0.8)
`

const Content = styled.div`

  padding: 40px 0;
  border-radius: 20px;
  overflow-y: auto;
     ${tw`relative shadow-lg`};
    background-color: ${props => props.theme.colors.deepBackground};
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-height: 80%;
    width:  min(100% - 40px, 800px);
    border-color: ${props => props.theme.colors.border};
    border-width: 1px ;
      z-index: 101;
      
     @media (max-width: 639px) {
       max-height: 90%;
    }

`


const PopupModal = ({ children, isOpen, close, isLoading }) => {


      if (isOpen) {
          return (
            <ModelWrapper>
                <BackdropWrapper id={"backdropWrapper"} onClick={ event => {
                      if (event.target.id === "backdropWrapper") {
                        close()
                      }
                      console.log(event.target.id)
                  }}>
                    <Content>
                       {children}
                    </Content>
                </BackdropWrapper>
            </ModelWrapper>
          )
      }

      return null


}

export  default PopupModal