

const dark = {
    background: "#000000",
    deepBackground: "#111111",
    legalBackground: "#11111190",
    primaryText: "rgb(218,218,218)",
    textInverse: "rgb(14, 14, 14)",
    mainShadow: "rgba(0,0,0, 0.6)",
    accent1: "#107CE7",
    primary: "#15C3EF",
    lightPrimary: "rgba(47,227,248, 0.1)",
    lightAccent: "#F5A62320",
    accent2: "#15C3EF",
    accent: "#F5A623",
    gray: '#ABA8A8',
    filter: 'invert(99%) sepia(0%) saturate(0%) hue-rotate(348deg) brightness(103%) contrast(101%);',
    cleanRed: "#FF6288",
    lightClearRed: "#FF628810",
    cleanOrange: "#FC9868",
    cleanYellow: "#FFD866",
    cleanGreen: "#A9DC76",
    cleanDarkerGreen: "#55783b",
    cleanBlue: "#79DCE8",
    cleanPurple:"#AB9DF2",
    lightCleanPurple:"#ab9df220",
    codeDarkBackground: "#212023",
    codeLightBackground: "#2D292D",
    codeLightGray: "#908E8F",
    border: "#292f36",
    blockquote: "rgba(245, 245, 245, 0.815)",
    overlayBg: "#181818",
}

const light = {
    background: "#ffffff",
    deepBackground: "#F1F5F9",
    legalBackground: "#F1F5F990",
    primaryText: "rgb(74, 74, 74)",
    textInverse: "rgb(218, 218, 218)",
    mainShadow: "rgba(138,149,158, 0.4)",
    accent1: "#107CE7",
    primary: "#107CE7",
    lightPrimary: "rgba(16,124,231, 0.1)",
    accent2: "#15C3EF",
    accent: "#D80E70",
    lightAccent: "rgba(224,17,113, 0.2)",
    gray: '#707376',
    filter: 'invert(0%) sepia(1%) saturate(4%) hue-rotate(334deg) brightness(96%) contrast(101%);',
    cleanRed: "#ff2c2c",
    lightClearRed: "#ff2c2c10",
    cleanOrange: "#FC9868",
    cleanYellow: "#107CE7",
    cleanGreen: "#00B849",
    cleanDarkerGreen: "#55783b",
    cleanBlue: "#79DCE8",
    cleanPurple:"#725AC1",
    lightCleanPurple:"#725AC120",
    codeDarkBackground: "#F8F6F1",
    codeLightBackground: "#2D292D",
    codeLightGray: "#908E8F",
    border: "#d0d5d7",
    blockquote: "rgba(0, 0, 0, 0.719)",
    overlayBg: "#DCDCDC",
}

export const getTheme = (scheme) => {

    let colors = {}
    var shadows = {}
    let name = ""

    if (scheme === 'dark') {
        colors = dark
        name = 'dark'
        shadows = {
            main: "0 30px 60px rgba(138,149,158, 0.4)"
        }
    } else {
        colors = light
        name = 'light'
        shadows = {
            main: "0 30px 60px rgba(138,149,158, 0.4)"
        }
    }

    return {
        name: name,
        fonts: {
            main: "Fira Sans, Work Sans, Montserrat,Roboto ,sans-serif",
            code: "Fira Code, monospace",
            secondary: "Montserrat,Roboto ,sans-serif",
            content: "Roboto , sans-serif"
        },
        
        colors: colors,
        measurements: {
            borderRadius: "20px",
            smBorderRadius: "12px",
            borderWidth: "1px",
            fontSize: "16px",
            smFontSize: "16px",
            mdFontSize: "16px",
            lgFontSize: "18px",
            xlFontSize: "18px",
            codeFontSize: "12px"
        }
    }
}
