import React from "react"
import styled from "styled-components"
import tw from "tailwind.macro"
import { A, GrayText, GrayTextWrapper, NakedAccentLink } from "../elements/shared/texts";
import { ValidatedInput, Checkbox } from "../elements/shared/inputs"
import { NakedAccentButton, NakedPrimaryButton, SubmitButton } from "../elements/shared/buttons";
import useValidation from "../helpers/useValidation"
import signUpValidation from "../helpers/validations/signUpValidation"
import AuthenticationService from "../web_service/AuthenticationService"
import Form from "../elements/shared/form/Form"
import { ResponseStatus } from "../utils/constants.js"
import { UseGlobalDispatchContext } from "../context/globalContext";
import { GlobalActionsType } from "../utils/constants";

const Container = styled.div`
  ${tw`flex flex-col items-center h-full`}
`

const BannerContainer = styled.div`
  background-color: ${props=> props.theme.colors.accent};
  color: ${props=> props.theme.colors.textInverse};
  padding: 20px;
  border-radius: 20px;
  font-weight: bold;
`

const INITIAL_STATE = {
  firstName:"",
  lastName:"",
  email: "",
  password: "",
  newsletter: false
}

const SignUp = ({showSignIn, setProcessing, setResponse}) => {
  const dispatch = UseGlobalDispatchContext()

  const authenticateUser = () => {
    setProcessing(true)
    const {firstName, lastName, email, password, newsletter } = values

    const data = {
          FirstName: firstName,
          LastName: lastName,
          Email: email.toLowerCase(),
          Password: password,
          Newsletter: newsletter
      }
      
      AuthenticationService.getService().signUp(data, res => {
        
        setResponse({
          status: ResponseStatus.Success,
          message: "Your account has been successfully created, but in order to start using it, you are required to verify your email address. Please, check your email inbox."
        })
      }, err => {
        if (err.response) {
          setResponse({
            status: ResponseStatus.Failure,
            message: err.response.data
          })
        } else {
          setResponse({
            status: ResponseStatus.Failure,
            message: "Oops! Something went wrong... Please check your internet connection"
          })
        }
      })
  }

  const showPrivacy = () => {
    dispatch({type: GlobalActionsType.PRIVACY, showPrivacy: true})
  }

  const showTerms = () => {
    dispatch({type: GlobalActionsType.TERMS, showTerms: true})
  }


  const { handleChange, handleBlur, errors, values, handleSubmit } = useValidation(INITIAL_STATE, signUpValidation, authenticateUser)



  return (
    <Container>
      <BannerContainer>
        Please check your spam folder if you don't get the confirmation email immediately.
      </BannerContainer>
      <Form onSubmit={handleSubmit}>

        <ValidatedInput
          onChange={handleChange}
          onBlur={handleBlur}
          name={"firstName"}
          value={values.firstName}
          placeholder={"First name"}
          message={errors.firstName}
        />
        <ValidatedInput
          onChange={handleChange}
          onBlur={handleBlur}
          name={"lastName"}
          value={values.lastName}
          placeholder={"Last name"}
          message={errors.lastName}
        />
        <ValidatedInput
          onChange={handleChange}
          onBlur={handleBlur}
          name={"email"}
          value={values.email}
          placeholder={"Email"}
          message={errors.email}
        />

        <div>

        <ValidatedInput
          onChange={handleChange}
          onBlur={handleBlur}
          name={"password"}
          value={values.password}
          placeholder={"Password"}
          message={errors.password}
          isSecure
        />
        </div>


        <p className={"pt-4"}>
          <small >By submitting, you also agree to our <NakedAccentButton onClick={() => { showPrivacy() }} >Privacy policy </NakedAccentButton> and<NakedAccentButton onClick={() => { showTerms() }} >Terms of service.</NakedAccentButton></small>
        </p>
      <div className="py-6" >


        <Checkbox
          name={"newsletter"}
          onChange={handleChange}
          value={values.newsletter}

          text={"Yes, I want to receive weekly newsletters about new courses and articles."} />
        {/*<small>*/}
        {/*  I will start sending email newsletters once in a while, so in order to get notified when I release new content, make sure to follow me on twitter <A*/}
        {/*  href="https://twitter.com/liquidcoder" target={"_blank"}>@liquidcoder</A> .*/}
        {/*</small>*/}
        </div>
        <SubmitButton />
      </Form>
      <GrayTextWrapper className={"pt-8 text-center"}>
        <span>
          Already have an account?&nbsp;
        </span>
         <NakedPrimaryButton onClick={showSignIn}>Sign in</NakedPrimaryButton>
      </GrayTextWrapper>
    </Container>
  )
}

export  default SignUp