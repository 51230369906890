import React from "react"
import styled, { css } from "styled-components"
import tw from "tailwind.macro"
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";


const InputWrapper = styled.div`

  label {
    position: relative;
    left: 10px;
    top: 45px;
    z-index: 1;
    transition: all 0.5s ease-in-out;
    color: ${props => props.theme.colors.border};
  }

  input {
    position: relative;
    z-index: 11;
  }

  svg {
    margin: 0 10px;
    cursor: pointer;
  }

  ${ props =>
        props.isFocused && css`
        label {
           top: 0;
           left: 0;
           font-size: 13px;
           color: ${props => props.theme.colors.gray};
        }
      `}

`

export const Input = styled.div`
  ${tw`flex items-center`}
      input {
        ${tw`px-2 py-3 w-full  focus:outline-none focus:shadow-outline`};
        background: transparent;
      }
    
    background: transparent;
    border-color: ${props => props.theme.colors.border};
    border-radius: ${props => props.theme.measurements.smBorderRadius};
    border-width: ${props => props.theme.measurements.borderWidth};
    z-index: 20;
     ${ props =>
        props.hasErrors && css`
          border-color: ${props => props.theme.colors.cleanRed} ;
      `
      }
`

const Small = styled("small")`
    ${tw``};
    color: ${props => props.theme.colors.cleanRed};
    
`

const Label = styled("label")`
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 16px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
   user-select: none;
   color: ${props => props.theme.colors.gray};


/* Hide the browser's default checkbox */
 input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  border-color: ${props => props.theme.colors.border};
  border-width: ${props => props.theme.measurements.borderWidth};
  border-radius: ${props => props.theme.measurements.smBorderRadius};
  background-color: ${props => props.theme.colors.deepBackground};
}

/* On mouse-over, add a grey background color */
&:hover input ~ .checkmark {
  background-color: ${props => props.theme.colors.border};
}

/* When the checkbox is checked, add a blue background */
 input:checked ~ .checkmark {
  background-color: ${props => props.theme.colors.primary};
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.checkmark:after {
  left: 9px;
  top: 5px;
  width: 5px;
  height: 10px;
  border: solid ${props => props.theme.colors.textInverse};
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
`

export const Checkbox = props => {

  return (
    <Label ><span dangerouslySetInnerHTML={{__html: props.text}} />
      <input type="checkbox" 
      onChange={props.onChange}
      name={props.name}
      value={props.value}
            />
      <span className="checkmark"/>
    </Label>
  )

}

export const ValidatedInput = (props) => {

  const [inputType, setInputType] = React.useState("password")
  const [isFocused, setFocus] = React.useState(false)

  const onBlur = () => {
    props.onBlur()
    if (!props.value) {
      setFocus(false)
    }
  }

  const onFocus = () => {
    setFocus(true)
  }

  const showPassword = () => {
    setInputType("text")
  }

  const hidePassword = () => {
    setInputType("password")
  }

    return (
      <InputWrapper isFocused={isFocused}>
          <label for={props.placeholder}>{props.placeholder}</label>
         <Input hasErrors={props.message}>
          <input
            type={props.isSecure ? inputType : "text"}
            onChange={props.onChange}
            onBlur={onBlur}
            onFocus={onFocus}
            name={props.name}
            value={props.value}

          />
          {
            props.isSecure && <div>
                {inputType === "password" ? <AiOutlineEye onClick={showPassword} /> :
                <AiOutlineEyeInvisible onClick={hidePassword} />}
            </div>
          }
      </Input>

          <Small > {props.message} </Small>
      </InputWrapper>
    )
}