import React from "react";
import { H1, H2, H3 } from "../elements/shared/texts";
import styled from "styled-components"
import tw from "tailwind.macro"

const Container = styled.div`
 ${tw`flex justify-center items-center `};
      background-image: linear-gradient(to right,  rgb(16, 124, 231), rgb(21, 195, 239));
    -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      padding: 20px 0;
      height: 100vh;
      
      h1{
      text-align: center;
      }

`

const ComingSoonComponent = () => {

  return (
    <Container>
        <H1>Coming soon!</H1>
    </Container>
  )
}

export  default ComingSoonComponent