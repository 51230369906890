import styled from "styled-components"
import Layout from "../Layout"
import ListHeader from "../ListHeader"
import React from "react"
import { H3, HeaderText, HeaderText2, HeaderText3 } from "../../elements/shared/texts";

const Container = styled.div`

 width: min(100% - 40px, 800px);
 margin: 0 auto;
 padding-bottom: 40px;

    #content h1 {
      font-size: 1.5rem;
      font-weight: 800;
      padding-top: 20px;
      padding-bottom: 10px;
      line-height: 1.2;
      font-family: ${props => props.theme.fonts.secondary};
      @media (max-width: 639px) {
        font-size: 1.5rem;
        font-weight: 600;
      }
    }

   #content h2 {
      font-size: 1.3rem;
      font-weight: 800;
      font-family: ${props => props.theme.fonts.secondary};
      @media (max-width: 639px) {
        font-size: 1.3rem;
        font-weight: 600;
      }
      padding: 20px 0;
    }

   #content h3 {
      font-size: 1.1rem;
      font-weight: 800;
      font-family: ${props => props.theme.fonts.secondary};
      @media (max-width: 639px) {
        font-size: 1.1rem;
        font-weight: 600;
      }
    }

    #content h4 {
      font-size: 1rem;
      font-weight: 800;
      font-family: ${props => props.theme.fonts.secondary};
      @media (max-width: 639px) {
        font-size: 1rem;
        font-weight: 600;
      }
    }
    
    p{
      padding: 10px 0;
    }
    
     a {
      color: ${props => props.theme.colors.accent};
      text-decoration: none;

      &:hover{
        color: ${props => props.theme.colors.primary};
        text-decoration: underline;
      }

    }
`

const LegalComponent = (props) => {

  return (
    <Container>
      <HeaderText3>{props.title}</HeaderText3>

      {
        props.html &&  <div id={"content"} dangerouslySetInnerHTML={{ __html: props.html }} />
      }

      {
        props.children && <div id={"content"}>
          { props.children}
        </div>
      }
    </Container>
  )
}

export default LegalComponent
