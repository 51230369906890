import React from "react"
import { blink } from "../../../styles/Keyframes"
import styled, { css } from "styled-components"


const Container = styled("span")`
  span{
    animation-name: ${blink};
    animation-duration: 1.4s;
    animation-iteration-count: infinite;
    animation-fill-mode: both;
    margin: 0 2px;
  }
  
  ${
    props => props.isBigger && css`
      margin: 0;
    `
  }
  
  span:nth-child(2) {
    animation-delay: .2s;
}

  span:nth-child(3) {
    animation-delay: .4s;
}
`

const LoadingDots = (props) => {

  if (props.isBigger) {
    return (
      <Container isBigger={true}>
        <span >&#9679;</span><span>&#9679;</span><span>&#9679;</span>
      </Container>
    )
  }

  return (
    <Container>
      <span>.</span><span>.</span><span>.</span>
    </Container>
  )
}

export default  LoadingDots