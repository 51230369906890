import React, { useEffect } from "react";
import PopupModal from "../PopupModal"
import LogIn from "../LogIn"
import { UseGlobalDispatchContext, UseGlobalStateContext } from "../../context/globalContext"
import SignUp from "../SignUp"
import ForgotPassword from "../ForgotPassword"
import { GlobalActionsType, ModalType } from "../../utils/constants"
import Privacy from "./privacy";
import Terms from "./terms";
import Disclaimer from "./disclaimer";


const LegalModal = () => {

  const { showPrivacy,  showTerms, showDisclaimer } = UseGlobalStateContext()
  const dispatch = UseGlobalDispatchContext()

  const close = () => {
      dispatch({type: GlobalActionsType.PRIVACY, showPrivacy: false})
    dispatch({type: GlobalActionsType.TERMS, showTerms: false})
    dispatch({type: GlobalActionsType.DISCLAIMER, showDisclaimer: false})
  }

  return (
    <PopupModal close={close} isOpen={showPrivacy || showTerms || showDisclaimer}>

      { showPrivacy &&  <Privacy /> }
      { showTerms &&  <Terms /> }
      { showDisclaimer &&  <Disclaimer /> }

    </PopupModal>
  )
}

export default LegalModal