import styled, { css } from "styled-components"
import tw from "tailwind.macro"
import { motion } from "framer-motion";
import { Link } from "gatsby";


export * from "./SubmitButton"
export * from "./MotionLinkWrapper"


export const AccentButton = styled(motion.button)`
  ${tw`block  text-white text-center focus:outline-none focus:shadow-outline`};
  background-color: ${props => props.theme.colors.accent};
  border-radius: ${props => props.theme.measurements.smBorderRadius};
  color: ${props => props.theme.colors.textInverse};
    padding: 8px 28px;
  font-size: 18px;
  font-weight:  normal ;
  &:hover{
    box-shadow: 0 20px 40px ${props => props.theme.colors.lightAccent};
  }  
`

export const AccentLink = styled(Link)`
  ${tw`block  text-white text-center focus:outline-none focus:shadow-outline`};
  background-color: ${props => props.theme.colors.accent};
  border-radius: ${props => props.theme.measurements.smBorderRadius};
  color: ${props => props.theme.colors.textInverse};
    padding: 8px 28px;
  font-size: 18px;
  font-weight:  normal ;
  &:hover{
    box-shadow: 0 20px 40px ${props => props.theme.colors.lightAccent};
  }  
`

export const PrimaryButton = styled(motion.button) `
 ${tw`block  py-2 px-6 text-white text-center focus:outline-none focus:shadow-outline`};
  background-color: ${props => props.theme.colors.primary};
  border-radius: ${props => props.theme.measurements.smBorderRadius};
  color: ${props => props.theme.colors.textInverse};

  &:hover{
    box-shadow: 0 10px 20px ${props => props.theme.colors.lightPrimary};
  }  
`

export const PrimaryLink = styled(Link) `
 ${tw`block text-center focus:outline-none focus:shadow-outline`};
  background-color: ${props => props.theme.colors.primary};
  color: ${props => props.theme.colors.textInverse};
  border-radius: ${props => props.theme.measurements.smBorderRadius};
  padding: 8px 28px;
  font-size: 18px;
  font-weight:  normal ;
  &:hover{
    box-shadow: 0 10px 20px ${props => props.theme.colors.lightPrimary};
  }  
`


export const Filled_A = styled("a")`
 ${tw`block   text-center focus:outline-none focus:shadow-outline my-4`};
     background-color: ${props => props.theme.colors.accent};
  border-radius: ${props => props.theme.measurements.smBorderRadius};
  color: ${props => props.theme.colors.textInverse};
    padding: 8px 28px;
  font-size: 18px;
  font-weight:  normal ;
  &:hover{
    box-shadow: 0 20px 40px ${props => props.theme.colors.lightAccent};
  }  
`


export const RoundedGradientLink = styled(Link)`
  ${tw`block  p-3  text-white font-medium text-sm sm:text-sm lg:text-md text-center focus:outline-none focus:shadow-outline`};
  background-color:  ${props => props.theme.colors.primary};
  color: ${props => props.theme.colors.textInverse};
  border-radius: 100%;
`

export const OutlineBtn = styled(motion.button)`
  ${tw`block`};
  border-color:  ${props => props.theme.colors.primary};
  border-width: 2px;
  border-radius: ${props => props.theme.measurements.smBorderRadius};
  color: ${props => props.theme.colors.primary};
    padding: 8px 28px;
  font-size: 18px;
  font-weight:  normal ;
`

export const OutlineLink = styled(Link)`
  ${tw`block  text-white text-sm sm:text-sm lg:text-md border-4 focus:outline-none focus:shadow-outline`};
  border-color: ${props => props.theme.colors.accent};
  border-radius: ${props => props.theme.measurements.smBorderRadius};
  color: ${props => props.theme.colors.accent};
    padding: 8px 28px;
  font-size: 18px;
  font-weight:  normal ;
`


export const OutlineGrayLink = styled(Link)`
  ${tw`block  text-white focus:outline-none focus:shadow-outline`};
  border-color: ${props => props.theme.colors.border};
  border-radius: ${props => props.theme.measurements.smBorderRadius};
  color: ${props => props.theme.colors.gray};
  border-width: ${props => props.theme.measurements.borderWidth};
  padding: 8px 28px;
  font-size: 18px;
  font-weight:  normal ;
      &:hover{
    border-color: ${props => props.theme.colors.primary};
    color: ${props => props.theme.colors.primary};
  }
`

export const GradientBtn = styled(motion.button)`
  ${tw`block  py-3 px-6 text-white text-sm sm:text-sm lg:text-md focus:outline-none focus:shadow-outline`};
  border-radius: ${props => props.theme.measurements.smBorderRadius};
  background-image: linear-gradient(
    -90deg,
    rgb(21, 195, 239),
    rgb(16, 124, 231)
  );
`


export const DangerButtom = styled(motion.button)`
  ${tw`block sm:inline sm:pr-4 lg:pr-6 text-center py-2 text-lg sm:text-sm lg:text-md focus:outline-none focus:shadow-outline`};
  color: ${props => props.theme.colors.cleanRed};
  
  &:hover{
    background-color: ${props => props.theme.colors.lightClearRed};
  }

`


export const LogoutButton = styled(motion.button)`
${tw`text-center focus:outline-none mx-auto w-full mt-4 md:mt-0`};
  color: ${props => props.theme.colors.cleanRed};
  padding: 10px;
  border-radius: ${props => props.theme.measurements.smBorderRadius};
`


export const DropdownMenu = styled.div`
    ${tw`flex justify-center  py-1 focus:outline-none focus:shadow-outline`};
    border-color: ${props => props.theme.colors.primary};
    color: ${props => props.theme.colors.primary};
    
    &:hover div {
      display: block;
      cursor: pointer; 
    }
`

export const DropdownContent = styled.div`
  ${tw`absolute focus:outline-none focus:shadow-outline`};
  display: none;
   background-color: ${props => props.theme.colors.deepBackground};
   border-color: ${props => props.theme.colors.border};
   border-radius: 15px;
   border-width: 1px;
   padding: 12px 16px;
   margin-top: 35px;
   z-index: 60;
`


export const NakedAccentButton = styled("button")`
      ${tw`underline ml-2  focus:outline-none focus:shadow-outline`};
      color: ${props => props.theme.colors.accent};  
      
       &:hover {
        color: ${props => props.theme.colors.primary};  
      }
`

export const NakedGrayButton = styled("button")`
      ${tw`underline ml-2  focus:outline-none focus:shadow-outline`};
      color: ${props => props.theme.colors.gray};  
      
      &:hover {
        color: ${props => props.theme.colors.primary};  
      }
`


export const NakedPrimaryButton = styled("button")`
      ${tw`underline focus:outline-none focus:shadow-outline`};
      color: ${props => props.theme.colors.primary};  


  &:hover{
    color: ${props => props.theme.colors.accent};
  }  
`

export const NakedDangerButton = styled("button")`
      ${tw`underline ml-2 font-semibold focus:outline-none focus:shadow-outline`};
      color: ${props => props.theme.colors.cleanRed};
`


export const NakedPrimaryLink = styled(Link)`
      ${tw`underline  font-semibold focus:outline-none focus:shadow-outline`};
      color: ${props => props.theme.colors.primary};  
`



