import React from "react"
import { motion } from "framer-motion"


export const MotionLinkWrapper = props => {

  return (
    <motion.div
      whileHover={{ scale: 1.1,  y: -5  }}
      whileTap={{ scale: 0.9, y: 0}}
    >
      {props.children}
    </motion.div>
  )
}