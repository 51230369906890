import styled from "styled-components";
import tw from "tailwind.macro"
import { motion } from "framer-motion";

export const PaddingContainer = styled(motion.div)`
  ${tw`py-16 xl:px-0 lg:px-8 md:px-6 px-4 `};
`

export const Py4 = styled.div`
    ${tw`py-4`}
`
export const Px4 = styled.div`
    ${tw`px-2`}
`

