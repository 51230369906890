import styled, { css }from "styled-components";
import tw from "tailwind.macro"
import { Link } from "gatsby"
import {gradientFlow} from "../../../styles/Keyframes"
import { motion } from "framer-motion"

export const H1 = styled("h1")`
  ${tw`text-4xl md:text-6xl tracking-tight font-bold sm:font-extrabold`};
  line-height: 0.9;
  //font-family: 'Montserrat', sans-serif;
`

export const VariableH1 = styled.div`
  ${tw`text-center sm:text-center lg:text-left`}
`

export const GradientTextWrapper = styled.div`
  color: ${props => props.theme.colors.primary};
  display: inline;
`


export const Small = styled("small")`
  ${tw`sm:text-sm text-xs`}
`

export const GrayTextWrapper = styled.div`
    color: ${props => props.theme.colors.gray};
`

export const BoldTextWrapper = styled.div`
  font-weight: bold;
`

export const H2 = styled("h2")`
  font-weight: 800;
  font-size: 3em;
  letter-spacing: -0.015em;
  line-height: 1.1em;
`

export const H3 = styled("h3")`
  font-weight: 800;
  font-size: 2.1em;
  letter-spacing: -0.015em;
  line-height: 1.1em;
`

export const H4 = styled("h4")`
  font-weight: 800;
  font-size: 1.4em;
  letter-spacing: -0.015em;
  line-height: 1.1em;

`

export const GrayText = styled.div`
    color: ${props => props.theme.colors.gray};
`

export const BodyText = styled.div`
    font-size: ${props => props.theme.measurements.fontSize};
    
     @media (min-width: 640px) { 
      font-size: ${props => props.theme.measurements.smFontSize};
    }
  
     @media (min-width: 768px) { 
      font-size: ${props => props.theme.measurements.mdFontSize};
    }
    
     @media (min-width: 1024px) { 
      font-size: ${props => props.theme.measurements.lgFontSize};
    }
    
     @media (min-width: 1280px) { 
      font-size: ${props => props.theme.measurements.xlFontSize};
    }
    
`

export const HeaderText = styled("h1")`
  font-size: max(6.5em, min(16vw, 20rem));
  line-height: 1;
  font-weight: 800;
  letter-spacing: -10px;
  animation: ${gradientFlow} 20s ease-in-out infinite alternate;
  padding-bottom: 10px;
  padding-left: 5px;
  padding-right: 5px;

  ${props => props.theme.name === "dark" && css `
    background: linear-gradient(-60deg,#107CE7 20%, #15C3EF, #F5A623, #D80E70, #107CE7 80%);
    background-size: 300%;
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
  `}

  @media (max-width: 640px) { 
    letter-spacing: 0;
  }

`



export const HeaderText2 = styled("h1")`
  //font-family: 'Montserrat', sans-serif;
  font-size: max(3em, min(8vw, 6rem));
  line-height: 1;
  font-weight: 800;
  letter-spacing: 0;
  background-image: linear-gradient(to right,  rgb(16, 124, 231), rgb(21, 195, 239));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;


   ${props => props.hasEffect && css`
        background-image: linear-gradient(to right,  rgb(16, 124, 231), rgb(21, 195, 239));
        -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
   `}
   
    ${props => props.isPrimary && css`
      color: ${props => props.theme.colors.primary};
   `}
   

`


export const HeaderText3 = styled("h1")`
  font-size: max(1.5em, min(3vw, 3rem));;
  line-height: 1;
  font-weight: 800;
  letter-spacing: 0;
  color: ${props => props.theme.colors.gray};
  
     ${props => props.isPrimary && css`
       background-image: linear-gradient(to right,  rgb(16, 124, 231), rgb(21, 195, 239));
       -webkit-background-clip: text;
       -webkit-text-fill-color: transparent;
   `}
   
`


export const ErrorText = styled("p")`
    ${tw`text-sm font-medium`};
    color: ${props => props.theme.colors.cleanRed};
`

export const ErrorWrapper = styled.div`
  color: ${props => props.theme.colors.cleanRed};
`

export const NavLink = styled(Link)`
  ${tw`block md:inline tracking-tight  sm:pr-4 lg:pr-6 text-center py-2 text-lg sm:text-sm lg:text-md`};
  color: ${props => props.theme.colors.primaryText};


  ${props => props.isNav && css`
  
      @media (max-width: 639px) {
        font-size: ${props => props.theme.measurements.xlFontSize};
        margin: 20px 0; 
      }
  `}

  &:hover {
    color: ${props => props.theme.colors.primary};
  }


`


export const P = styled("p")`
 ${tw`text-center`};
  font-size: 25px;
  margin-top: 40px;
  
   @media (max-width: 639px) {
    font-size: 15px;
  }
  
   @media (max-width: 768px) {
      font-size: 18px;
     
   }
`

export const A = styled("a")`
     color: ${props => props.theme.colors.accent};
      text-decoration: none;

      &:hover{
        color: ${props => props.theme.colors.primary};
        text-decoration: underline;
      }

`

export const NakedGrayAnchorTag = styled("a")`
     color: ${props => props.theme.colors.gray};

      &:hover{
        color: ${props => props.theme.colors.primary};
      }

`


export const OutlineGrayAnchor = styled(motion.a)`
  ${tw`block  text-white focus:outline-none focus:shadow-outline`};
  border-color: ${props => props.theme.colors.border};
  border-radius: ${props => props.theme.measurements.smBorderRadius};
  color: ${props => props.theme.colors.gray};
  border-width: ${props => props.theme.measurements.borderWidth};
    padding: 8px 28px;
  font-size: 18px;
  font-weight:  normal ;
  
    &:hover{
    border-color: ${props => props.theme.colors.primary};
    color: ${props => props.theme.colors.primary};
  }
`



export const NakedAccentLink = styled(Link)`
     color: ${props => props.theme.colors.accent};
      text-decoration: none;

      &:hover{
        color: ${props => props.theme.colors.primary};
        text-decoration: underline;
      }

`

export const TagText = styled("small")`
    padding: 5px 8px;
    background: ${props => props.theme.colors.background};
    border: 0.3px solid ${props => props.theme.colors.border};
    margin-right: 5px;
    border-radius: 10px;
`


