import  React  from "react"
import { useStaticQuery, graphql } from "gatsby"
import styled from "styled-components"
import SocialMedia  from "../components/SocialMedia";
import Subscribe  from "../components/Subscribe";
import MenuItem from "./MenuItem"
import { BodyText,H4, GrayTextWrapper } from "../elements/shared/texts"
import { PaddingContainer } from "../elements/shared/containers"

const BgContainer = styled.div`
   border-top: 0.5px solid ${props => props.theme.colors.border};
   background: ${props => props.theme.colors.deepBackground};
`

const Footer = () => {
  const data = useStaticQuery(
    graphql`
      query {
        overlay: file(relativePath: { eq: "overlay.svg" }) {
          publicURL
        }
        twitter: file(relativePath: { eq: "twitter.svg" }) {
          publicURL
        }
        instagram: file(relativePath: { eq: "instagram.svg" }) {
          publicURL
        }
        facebook: file(relativePath: { eq: "facebook.svg" }) {
          publicURL
        }
      }
    `
  )


  return (
    <BgContainer>
      <PaddingContainer>
      <div className="container mx-auto ">
        <div className="flex flex-col md:flex-row justify-between sm:mx-0 mx-4 ">
          <Subscribe />
          <div className="flex  justify-between  md:w-2/5 w-full pt-8 md:pt-0  ">
            <SocialMedia data={data}/>
            <div className="mx-auto text-left mt-8 sm:mt-0">
              <GrayTextWrapper>
                <BodyText className="px-2">
                  <H4>
                    Main site:
                  </H4>
                </BodyText>
              </GrayTextWrapper>
              <div className=" mx-auto md:mx-0 mt-2 ">
                <MenuItem title={"Home"} path={"/"}/>
                <MenuItem title={"Courses"} path={"/courses"}/>
                <MenuItem title={"Blog"} path={"/blog"}/>
                <MenuItem title={"Sponsors"} path={"/sponsors"}/>
                {/* <MenuItem title={"Pricing"} isExternal={"true"}  path={"#pricing"}/> */}
                <MenuItem title={"Work with us"} isExternal={"true"}  path={"#work"}/>
              </div>
            </div>
          </div>
        </div>
      </div>
      </PaddingContainer>
    </BgContainer>
  )
}

export default Footer
