import React, { useEffect, useRef, useState } from "react"
import { Navbar, Logo, MenuItems, MenuContainer } from "../elements/nav"
import { useStaticQuery, Link, graphql } from "gatsby"

import {
  UseGlobalStateContext,
  UseGlobalDispatchContext,
} from "../context/globalContext"

import { GlobalActionsType } from "../utils/constants"
import tw from "tailwind.macro"
import styled, { css } from "styled-components"
import MenuItem from "./MenuItem"
import HambourgerButton from "./HambourgerButton"

import { ThemeButton } from "../elements/nav"
import { getTheme } from "../themes/theme"
import { navigate } from "@reach/router"
import AuthenticationService from "../web_service/AuthenticationService"
import { IsBrowser } from "../helpers/utils"
import { FaMoon } from "react-icons/fa"
import { MdWbSunny } from "react-icons/md"
import { UserLoggedInState } from "../utils/constants"
import DynamicAuthComponent from "./DynamicAuthComponent"

const NavbarWrapper = styled.div`
  ${tw`fixed w-full top-0 z-40 h-24`};
  background-color: ${props => props.theme.colors.background};
  //border-bottom: 1px solid ${props => props.theme.colors.border};
  height: 80px; 
 
`

const NavigationBar = () => {
  const [isMenuOpen, toggleMenu] = useState(false)
  const [isTop, setIsTop] = useState(true)
  const [isLoggedIn, setLoggedIn] = useState(false)
  const [loggedInState, setLoggedInState] = useState(UserLoggedInState.LOADING)
  const { currentTheme, userData } = UseGlobalStateContext()

  const dispatch = UseGlobalDispatchContext()
  const theme = getTheme(currentTheme)
  const data = useStaticQuery(
    graphql`
      query {
        logo: file(relativePath: { eq: "logo.svg" }) {
          publicURL
        }
        dark: file(relativePath: { eq: "dark.svg" }) {
          publicURL
        }
        light: file(relativePath: { eq: "light.svg" }) {
          publicURL
        }
      }
    `
  )

  const toggleTheme = () => {
    if (currentTheme === "dark") {
      dispatch({ type: GlobalActionsType.TOGGLE_THEME, theme: "light" })
    } else {
      dispatch({ type: GlobalActionsType.TOGGLE_THEME, theme: "dark" })
    }
  }

  const login = () => {
    dispatch({ type: GlobalActionsType.SHOW_LOGIN_MODAL, showModal: true })
  }

  const signOut = () => {
    AuthenticationService.getService().logout(() => {})
    dispatch({ type: GlobalActionsType.USER, userData: false })
    if (IsBrowser) {
      navigate("/")
    }
  }

  useEffect(() => {
    window.localStorage.setItem("theme", currentTheme)
  }, [currentTheme])

  useEffect(() => {
    window.addEventListener("scroll", handleScroll)
    return () => {
      window.removeEventListener("scroll", handleScroll)
    }
  }, [])

  // useEffect(() => {
  //   setLoggedIn(userData)
  //   if (userData) {
  //     setLoggedInState(UserLoggedInState.LOGGED_IN)
  //   } else {
  //     setLoggedInState(UserLoggedInState.NOT_LOGGED_IN)
  //   }
  // }, [userData])

  const handleScroll = event => {
    if (window.scrollY === 0) {
      setIsTop(true)
    } else {
      if (isTop) {
        setIsTop(false)
      }
    }
  }

  return (
    <NavbarWrapper isTop={!isTop}>
      <div className={"container mx-auto h-full flex items-center "}>
        <Navbar>
          <Link to="/" className={"z-50"}>
            <Logo src={data.logo.publicURL} alt="Liquidcoder logo" />
          </Link>

          <div className="md:flex items-center hidden">
            <MenuItem path="/" isNav={"true"} title={"HOME"} />
            <MenuItem path="/courses" isNav={"true"} title={"COURSES"} />
            <MenuItem path="/blog" isNav={"true"} title={"BLOG"} />
            {/* <MenuItem path="/pricing" isNav={"true"} title={"PRICING"} /> */}
            <MenuItem path="/work" isNav={"true"} title={"WORK"} />

            {/* <DynamicAuthComponent
              theme={theme}
              loggedInState={loggedInState}
              signOut={signOut}
            /> */}
          </div>

          <div className="flex items-center ">
            <ThemeButton
              onClick={() => toggleTheme()}
              whileTap={{ scale: 0.95 }}
              whileHover={{ scale: 1.1, rotate: 180 }}
            >
              {currentTheme === "dark" ? (
                <MdWbSunny size="30px" />
              ) : (
                <FaMoon size="25px" />
              )}
            </ThemeButton>

            <HambourgerButton
              toggleMenu={() => {
                toggleMenu(!isMenuOpen)
              }}
              isOpen={isMenuOpen}
            />
          </div>

          <MenuContainer
            isOpen={isMenuOpen}
            onClick={() => {
              toggleMenu()
            }}
          >
            <div>
              <MenuItems
                switchTheme={() => toggleTheme()}
                login={() => login()}
                themeIconURL={
                  currentTheme === "dark"
                    ? data.dark.publicURL
                    : data.light.publicURL
                }
                isMobile={false}
                isLoggedIn={isLoggedIn}
              />
            </div>
          </MenuContainer>
        </Navbar>
      </div>
    </NavbarWrapper>
  )
}

export default NavigationBar
